import React, { useState } from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import {
  Head,
  Hero,
  Breadcrumb,
  Lazyload,
  Button,
  Values,
  Testimonials,
  Footer,
} from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/About.module.styl'
import * as st1 from '~/assets/styl/Hero.module.styl'
import * as st2 from '~/assets/styl/Values.module.styl'

import Banner from '~/assets/img/Sobre-nos.jpg'
import Words from '~/assets/svg/Sonhar-planejar-construir.svg'
import Mission from '~/assets/img/Missao.jpg'

interface Context {
  motives: Array<{
    id: number
    name: string
    description: string
  }>
  numbers: Array<{
    id: number
    name: string
    description: string
  }>
  importances: Array<{
    id: number
    name: string
    description: string
    image: string
  }>
  testimonials: Array<{
    id: number
    name: string
    description: string
    image: string
  }>
}

const About = ({ location, pageContext }: PageProps) => {
  const { motives, importances, testimonials }: Context = useApi(
    pageContext,
    'page-about'
  )

  const [active, setActive] = useState(0)

  return (
    <>
      <Head
        location={location}
        title={'Quem somos - ' + process.env.GATSBY_SITE_NAME}
      />

      <Hero title="Quem somos" image={Banner} />

      <Breadcrumb />

      <section className={st.intro}>
        <div className={st.container}>
          <div>
            <span className={st1.pretitle}>
              Conheça a Votis Empreendimentos
            </span>
            <h2>Nosso propósito é a satisfação de cada cliente.</h2>
          </div>
          <p>
            A Construtora Votis nasce num tempo onde os valores são diferentes,
            mas nasce com bastante experiência. Com foco no crescimento e
            inovação, já entregou mais de 150mil m², sempre colocando a
            qualidade de vida das pessoas acima de tudo, através de projetos
            pensados no bem-estar e conforto, entregando qualidade, acabamento
            diferenciado e pontualidade. Cada metro quadrado é projetado para
            transformar em realidade sonhos e desejos de nossos clientes.
            Acompanhar a satisfação de cada um deles é nossa maior inspiração e
            motivo de orgulho de nossa equipe, pois são pessoas que sabem muito
            bem o que querem, e assim como nós, acreditam em seus sonhos.
          </p>
        </div>
        <div className={st.container}>
          <Lazyload
            type="img"
            src={Words}
            width="500"
            height="500"
            alt="Sonhar, Planejar e Construir"
          />
        </div>
      </section>

      <section className={cn(st2.core, st.mission)}>
        <div className={st2.container}>
          <Lazyload src={Mission} className={cn(st2.img, st2.active)} />

          <div className={st2.text}>
            <p>
              Nossa missão é proporcionar a nossos clientes soluções
              imobiliárias com segurança e qualidade.
            </p>
            <Button href="/empreendimentos/">Conheça os empreendimentos</Button>
          </div>
        </div>
      </section>

      <section className={st.motives}>
        <div className={st.container}>
          <div>
            <h2>5 motivos para escolher um apartamento Votis</h2>
            <div>
              <div className={st.selector}>
                {motives.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setActive(index)}
                    className={index === active ? st.active : null}
                  >
                    <span>{index + 1}</span>
                  </button>
                ))}
              </div>
              <ol>
                {motives.map(({ name, description }, index) => (
                  <li
                    key={index}
                    className={index === active ? st.active : null}
                  >
                    <h3>
                      <span>{index + 1}</span>
                      {name}
                    </h3>
                    <p>{description}</p>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* <Numbers button={false} className={st.numbers} /> */}

      <Values items={importances} noPadding={Boolean(testimonials?.length)} />

      <Testimonials items={testimonials} />

      <Footer />
    </>
  )
}

export default About
